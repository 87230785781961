import * as React from 'react';
import { Box, Typography, AppBar, Toolbar } from '@mui/material';
import { SearchIconWrapper, Search, StyledInputBase } from '../../layout/ui';
import SearchIcon from '../../assets/images/icon-search.svg';
import { Link } from 'react-router-dom';


const SearchBar = ({ label, off, searchKeyword, search }) => {
  const submitSearch = (event) => {
    if (event.key === 13 || event.key === 'Enter') {
      event.preventDefault();
      search()
    }
  }
  return (
    <Box sx={{ flexGrow: 1,background: '#000' }}>
      <AppBar position="static" sx={{boxShadow: 'none', paddingTop:'17px'}}>
        <Toolbar sx={{ p: '0 12px !important' }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, ml: '10px' }}
            color={'#00704A'}
            fontWeight={500}
          >
            {label}
          </Typography>
          {off === "search" ? <Search>
            <SearchIconWrapper onClick={() => search()}><img src={SearchIcon} alt="search" /></SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => searchKeyword(e.target.value)}
              onKeyUp={(e) => submitSearch(e)}
            />
          </Search> : off === "user" ? <Link to="/create-user" className="user-link">Create User</Link> : null}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default SearchBar;
