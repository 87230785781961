import Request from '../../utils/Request';

const getTopAlerts = async (sortBy, sortDirection) => {
  const params = {}
  if(sortBy){
    params.sort_by = sortBy
  }
  if(sortDirection){
    params.sort_direction = sortDirection
  }
  return await Request.get('device/topalerts', {params})
}

const getTopDevices = async (sortBy, sortDirection) => {
  const params = {}
  if(sortBy){
    params.sort_by = sortBy
  }
  if(sortDirection){
    params.sort_direction = sortDirection
  }
  return await Request.get('device/topdevicesbydatausage', {params})
}

const getMerakiDeviceStatus = async () => {
  return await Request.get('device/merakistates')
}

const getCradleDeviceStatus = async () => {
  return await Request.get('device/cradlepointstates')
}

const getDeviceByStatus = async () => {
  return await Request.get('device/devicesstatus')
}

const getStatusAverageTime = async () => {
  return await Request.get('device/status_avg_time')
}

const getAllDevices = async (status, page, sortBy, sortDirection,url) => {
    const params = {}
    if(status && status!=='' && !url?.includes('status')){
      params.status = status
    }
    if(sortBy && !url?.includes('sort_by')){
      params.sort_by = sortBy
    }
    if(sortDirection && !url?.includes('sort_direction')){
      params.sort_direction = sortDirection
    }
    let getUrl = url?url:'device/?limit=25'
    return await Request.get(getUrl,{params})
}

const getAllAlerts = async (sortBy, sortDirection,url) => {
  const params = {}
  if(sortBy && !url?.includes('sort_by')){
    params.sort_by = sortBy
  }
  if(sortDirection && !url?.includes('sort_direction')){
    params.sort_direction = sortDirection
  }
  let getUrl = url?url:'device/getalerts?limit=25'
  return await Request.get(getUrl, {params})
}

const createATicket = async (id) => {
  const params = { 'id': id }
  return await Request.get('device/createticket', {params})
}

const updateDeviceStage = async (query, deviceId) => {
    return await Request.patch(`device/${deviceId}`, query)
}

const getDeviceById = async (id) => {
  return await Request.get(`device/${id}`)
}

const searchDevicesApi = async (keyword, sortBy, sortDirection,url,status) => {
  const params = {};
  params.limit = 25;
  if(keyword && keyword!=='' ? keyword : ''){
    params.search =keyword
  }
  if(sortBy){
    params.sort_by = sortBy
  }
  if(sortDirection){
    params.sort_direction = sortDirection
  }
  if(status){
    params.status = status
  }
  let SearchUrl = url?url:'device/search'
  return await Request.get(SearchUrl, url?{}:{params})
}


export { 
  getTopAlerts, 
  getTopDevices,
  getMerakiDeviceStatus,
  getCradleDeviceStatus,
  getStatusAverageTime,
  getAllDevices,
  getAllAlerts,
  getDeviceByStatus,
  createATicket,
  updateDeviceStage,
  getDeviceById,
  searchDevicesApi,
}