import React from 'react';
import { Container, CssBaseline, Typography, Button, Box } from '@mui/material';
import MainLogo from '../../assets/images/login-logo.png';
import checkIcon from '../../assets/images/check.svg';
import { useNavigate } from 'react-router-dom';

const PasswordChanged = () => {
  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="xl" className="main-container" sx={{ display: 'flex', backgroundColor: '#007B51', minHeight: '100vh' }} >
      <CssBaseline />
      <Box className="auth">
        <img src={MainLogo} alt="logo" className='main-logo' />
        <Typography component="h1" variant="h5">Password Set Successfully!</Typography>
        <Box component="form" noValidate sx={{ mt: 3, width: '100%' }}>
          <img src={checkIcon} style={{ width: '22px', display: 'block', margin: '0 auto' }} alt="icon" />
          <Button
            type="submit"
            variant="contained" fullWidth sx={{ p: '10px 0', m: '15px 0' }} color='primary' onClick={() => navigate('/login')}>Go Back To Log in</Button>
        </Box>
      </Box>
    </Container>
  )
}

export default PasswordChanged