import React, {useState} from 'react';
import { Container, CssBaseline, Typography, Button, Grid, Box } from '@mui/material';
import InputField from '../../common/InputField';
import MainLogo from '../../assets/images/login-logo.png';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../services/apis/authApi'

const SetPassword = () => {
  const navigate = useNavigate();
  const [resetPwdErrorMsg, setResetPwdErrorMsg] = useState('')

  const validateNewPwd = event => {
    event.preventDefault()
    const elem = event.target.elements;
    const hashStr = window.location.pathname.split('set-password/');
    if (hashStr && hashStr[1] && hashStr[1] !== '') {
      if (elem.password.value === '' || elem.confirm_password.value === '') {
        setResetPwdErrorMsg("Please provide Password and Confirm Password.");
      } else if (elem.password.value !== elem.confirm_password.value) {
        setResetPwdErrorMsg("Password and Confirm Password doesn't match.");
      } else {
        resetPassword(hashStr[1], elem.password.value, elem.confirm_password.value).then(
          res => {
            if (res && res.status === 200 && res.data) {
              navigate("/password-changed");
            } else {
              setResetPwdErrorMsg(res.message);
            }
          }
        ).catch(err => {
          setResetPwdErrorMsg(err.response.data.detail);
          console.error(err);
        })
      }
    } else {
      setResetPwdErrorMsg('Your request is expired - Please contact Website administrator.');
    }


  }
  return (
    <Container component="main" maxWidth="xl" className="main-container" sx={{ display: 'flex', backgroundColor: '#007B51', minHeight: '100vh' }} >
      <CssBaseline />
      <Box className="auth">
        <img src={MainLogo} alt="logo" className='main-logo' />
        <Typography component="h1" variant="h5">Reset password</Typography>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={validateNewPwd}>
          <Grid container>
            <InputField name="password" label="Password" type='password' placeholder="Password" />
            <InputField name="password" label="Password" type='password' placeholder="Confirm Password" />
          </Grid>
          {resetPwdErrorMsg && <Typography variant="body2" color="error">{resetPwdErrorMsg}</Typography>}
          <Button
            type="submit"
            variant="contained" fullWidth sx={{ p: '10px 0', m: '15px 0' }} color='primary'>Update</Button>
        </Box>
      </Box>
    </Container>
  )
}

export default SetPassword;