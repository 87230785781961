import * as AuthActions from '../actions/authAction';

const initialState = {
    user: null,
    access_token: null,
    refresh_token: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthActions.USER_LOGIN:
      return {
        ...state,
        user: action.payload,
        access_token: action.payload?.access,
        refresh_token: action.payload?.refresh,
      };
    case AuthActions.USER_LOGOUT:
      return {
        ...state,
        user: null,
        access_token: null,
        refresh_token: null,
      };
    case AuthActions.REFRESH_TOKEN:
      return {
        ...state,
        access_token: action.payload?.access,
      }
    default:
      return state;
  }
}

export default authReducer;