import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import Navigation from './layout/Navigation';
import { BrowserRouter } from 'react-router-dom';
import './App.scss'

function App() {
  let theme = createTheme()
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
