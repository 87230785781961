import React, { useState, useEffect } from 'react';
import { Box, ListItem, ListItemText, List, Divider, Collapse, Typography, ListItemIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Refresh from '../utils/Refresh';
import * as AuthActions from '../services/actions/authAction'
import Request from '../utils/Request';
import { DrawerHeader, SideNav } from './ui';
import SideBarLogo from '../assets/images/login-logo.png';
import ToggleIcon from '../assets/images/menu.svg';
import StatsIcon from '../assets/images/stats.svg';
import LogoutIcon from '../assets/images/logout.svg';
import DeviceIcon from '../assets/images/devices.svg';
import BellIcon from '../assets/images/bell.svg';
import UserIcon from '../assets/images/user.svg';
import './Sidebar.scss'

const SidebarNavigation = [
  {
    title: "Dashboard",
    link: "/dashboard",
    subnav: [],
    icon: StatsIcon
  },
  {
    title: "Devices",
    link: "/devices",
    icon: DeviceIcon,
    subnav: [
      {
        title: 'Staging',
        link: '/staging',
      },
      {
        title: 'Baseline',
        link: '/baseline',
      },
      {
        title: 'Production',
        link: '/production',
      },
      {
        title: 'Maintenance',
        link: '/maintenance',
      },
      {
        title: 'Pending',
        link: '/pending',
      },
      {
        title: 'Hold',
        link: '/hold',
      },
    ]
  },
  {
    title: "Alerts",
    link: "/alerts",
    icon: BellIcon,
    subnav: []
  },
  {
    title: "Users",
    link: "/users",
    icon: UserIcon,
    subnav: []
  },
  {
    title: "Logout",
    link: "/logout",
    icon: LogoutIcon,
    subnav: []
  },

]

const Sidebar = ({ children, open, width, setWidth }) => {
  const [toggle, setToggle] = useState(0);
  const navigate = useNavigate();
  const refresh = useSelector(state => state.auth.refresh_token);
  const [error, setError] = useState(false)
  const dispatch = useDispatch();

  Request.interceptors.response.use(response => response, error => {
    if (error.response.status === 401) {
      setError(true)
      return Promise.reject(error);
    }
  });

  useEffect(() => {
    if (error) {
      Refresh(refresh)
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: AuthActions.REFRESH_TOKEN,
              payload: res.data,
            });
            localStorage.setItem("access", res.data.access);
            setError(false)
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            dispatch({
              type: AuthActions.USER_LOGOUT,
              payload: null,
            });
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
            navigate("/login");
            setError(false)
          }
        });
    }
  }, [error]);

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#000000' }}>
      <SideNav
        className='main-sidebar'
        variant="permanent" open={open}
        color='#2E2E2E'
        drawerWidth={width}
      >
        <DrawerHeader style={{ justifyContent: 'start', marginTop: 21 }}>
          <img src={ToggleIcon} className='toggle-icon' onClick={()=>{
            if(width === 240) setWidth(65)
            else setWidth(240) 
          }} />
          <img
            src={SideBarLogo}
            srcSet={SideBarLogo}
            alt={"Logo"}
            loading="lazy"
            width={"36spx"}
          />
          <Typography variant='h5' style={{ color: '#fff' }} fontSize={20}>SysMon</Typography>
        </DrawerHeader>
        <Divider />
        <List style={{ color: '#fff', paddingTop:'2px' }} >
          {SidebarNavigation.map(({ title, subnav, link, icon }, index) => {
            return (<React.Fragment key={title}>
              <ListItem button key={title} onClick={() => {
                if (link === '/logout') {
                  dispatch({
                    type: AuthActions.USER_LOGOUT,
                    payload: null,
                  });
                  navigate("/login");
                } else {
                  navigate(link)
                }
                setToggle(index)
              }} className={toggle === index ? "active" : ""}>
                <ListItemIcon>
                  <img src={icon} />
                </ListItemIcon>
                <ListItemText primary={title} />
              </ListItem>
              <Collapse in={(toggle === index && width !== 65) ? true : false} timeout="auto" unmountOnExit sx={{ml:'42px'}} >
                <List component="div" disablePadding>
                  {subnav?.map(({ title: subTitle, link: subLink }) => (
                    <ListItem button key={subTitle} onClick={() => navigate(`${link}${subLink}`)} style={{ marginLeft: 30 }} >
                      <ListItemText primary={subTitle} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
            )
          })}
        </List>
      </SideNav>
      {children}
    </Box>
  );
}

export default Sidebar;