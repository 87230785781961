import React, { useState, useEffect } from 'react';
import { Box, TableRow, TableCell } from '@mui/material'
import DenseTable from '../../common/DenseTable';
import SearchBar from '../../common/SearchBar';
import { UsersTableColumns } from '../../utils/Constraints';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getAllUsers, unBlockUser, deleteUser } from '../../services/apis/authApi';
import CheckIcon from '../../assets/images/check.svg';
import UserIcon from '../../assets/images/ic-user-gray.svg';
import ActiveIcon from '../../assets/images/ic-user-green.svg';
import DeleteIcon from '../../assets/images/ic-delete.png';
import Pagination from '../../common/Pagination';

const Users = () => {
  const [currentSortDirection, setCurrentSortDirection] = useState('')
  const currentUser = useSelector(state => state.auth.user);
  const access = useSelector(state => state.auth.access_token);
  const [allUsers, setAllUsers] = useState([])
  const [paginationIndex, setPaginationIndex] = useState(1)
  const [totalRecords, setTotalRecords] = useState()
  const limit = 25
  const [reloader, setReloader] = useState(false)

  const roles = {
    super_admin: 'Super Admin',
    admin: 'Admin',
    read_only: 'Read Only'
  }


  useEffect(() => {
    if (access && access !== "") {
      users(paginationIndex, limit)
    }
  }, [reloader, access])

  const users = (page, limit) => {
    getAllUsers(page, limit).then(result => {
      if (result && result.status === 200 && result.data) {
        setAllUsers(result.data.results);
        setTotalRecords(result.data.count);
      }
    })
  }

  const sortDataColumn = (col) => {
    if (currentSortDirection == '') {
      setCurrentSortDirection('asc')
      setAllUsers(_.sortBy(allUsers, col))
    } else if (currentSortDirection === 'asc' || currentSortDirection === 'desc') {
      if (currentSortDirection === 'asc') {
        setAllUsers(_.sortBy(allUsers, col).reverse())
        setCurrentSortDirection('desc')
      } else {
        setAllUsers(_.sortBy(allUsers, col))
        setCurrentSortDirection('asc')
      }
    }
  }

  const blockUnblockUser = (user) => {
    if (user.is_blocked === true) {
      unBlockUser(user.id).then(
        res => {
          if (res && res.status === 200) {
            console.error('RESPONSE =>', res);
            setReloader(prev => !prev)
          } else {
            console.error(res.message)
          }
        }
      )
    }
  }

  const delUser = (user) => {
    deleteUser(user.id).then(
      res => {
        console.error('RESPONSE =>', res);
        if (res && res.status === 204) {
          setReloader(prev => !prev)
        } else {
          console.error(res.message)
        }
      }
    )
  }

  const handlePreviousPage = () => {
    if (paginationIndex > 1) {
      setPaginationIndex(paginationIndex - 1)
      users(paginationIndex - 1, limit)
    }
  }

  const handleNextPage = () => {
    let totalPage = totalRecords / limit;
    if (paginationIndex < totalPage) {
      setPaginationIndex(paginationIndex + 1)
      users(paginationIndex + 1, limit)
    }
  }
  return (
    <>
      <SearchBar label="Users" off="user" />
      <Box sx={{ p: '20px 10px' }} className="main-border">
        <DenseTable head={UsersTableColumns} getData={sortDataColumn}>
          {allUsers.length > 0 ? allUsers?.map(item => (
            <TableRow
              key={item.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 }
              }}
            >
              <TableCell>{item.first_name}</TableCell>
              <TableCell>{item.last_name}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{roles[item.user_type]}</TableCell>
              <TableCell>{item.is_active === true && <img src={CheckIcon} alt="check" width="16px" />}</TableCell>
              <TableCell onClick={() => item.is_blocked && blockUnblockUser(item)}>{!item.is_blocked ? <img src={ActiveIcon} /> : <img src={UserIcon} />}</TableCell>
              <TableCell>{(currentUser?.user_type === "admin" || currentUser?.user_type === "super_admin") &&
                <img onClick={() => delUser(item)} src={DeleteIcon} width="20px" />
              }</TableCell>
            </TableRow>
          )) : <TableRow><TableCell colSpan={UsersTableColumns.length} sx={{ textAlign: 'center' }}>No User</TableCell></TableRow>}
        </DenseTable>
          {totalRecords>0 &&<Pagination totalRecords={totalRecords} limit={limit} handlePreviousPage={handlePreviousPage} paginationIndex={paginationIndex} handleNextPage={handleNextPage} />}
      </Box>
    </>
  )
}

export default Users