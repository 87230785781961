
import React from "react";
import { Box, Typography, IconButton } from "@mui/material";

const Pagination = ({ totalRecords, limit, handlePreviousPage, paginationIndex, handleNextPage }) => {

    return (
        <Box sx={{ display: 'flex', mt: 2, float: 'right', gap: 1, 'button': { width: '30px', borderRadius: '4px', color: '#000', backgroundColor: '#fff', fontSize: '14px', '&:hover': { backgroundColor: '#fff' } } }}>
            {totalRecords > limit &&
                <IconButton onClick={() => handlePreviousPage()}>{`<`}</IconButton>
            }
            <Typography sx={{ color: '#fff', pt: '7px' }} component="span">{paginationIndex === 1 ? 1 : ((paginationIndex - 1) * limit) + 1}-{((paginationIndex - 1) * limit + limit >= totalRecords) ? totalRecords : (paginationIndex * limit)} of {totalRecords}</Typography>
            {totalRecords > limit &&
                <IconButton onClick={() => handleNextPage()}> {'>'} </IconButton>
            }
        </Box>
    )
}

export default Pagination;
