import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import PreLoader from '../PreLoader';
import PieChart from '../PieChart';
import './style.scss'

const CradleStats = ({ data, loader }) => {
    const cradleDataForChart = {
        data: [
            { value: data.online, name: 'Cradlepoint Online' },
            { value: data.offline, name: 'Cradlepoint Offline' }
        ],
        colors: [
            '#00704A',
            '#D8493E'
        ]
    }
    return (
        <Box className='cradle-stats'>
            {/* <Card className='cradle-status'>
                <h5>Cradlepoint Online</h5>
                <p>{data?.online}</p>
            </Card>
            <Card className='cradle-status'>
                <h5>Cradlepoint Offline</h5>
                <p>{data?.offline}</p>
            </Card> */}
            <Card sx={{ minWidth: 275, backgroundColor: 'transparent', color: '#fff', boxShadow: 'none' }}>
                <Typography fontSize={'20px'} sx={{mb: '-15px', mt: '10px'}} textAlign={'center'}>Cradlepoint</Typography>
                {loader ?
                    <PreLoader />
                    :
                    <PieChart data={cradleDataForChart} />
                }
            </Card>
        </Box>
    )
}

export default CradleStats