export const DevicesColors = {
    staging: '#9a9ea2',
    baseline: '#007bff',
    production: '#28a745',
    maintenance: '#ffc107',
    pending: '#ff98ff',
}

export const TopAlertTableColumns = [{ id: 'name', name: 'Name' }, { id: 'priority', name: 'Priority' }, { id: 'state', name: 'State' }, { id: 'mac', name: 'MAC' }, { id: 'asset_id', name: 'Asset ID' }, { id: 'description', name: 'Description' }, { id: 'custom2', name: 'Order' }, { id: 'group_id__group_name', name: 'Group' }, { id: 'netdevices__carrier_id', name: 'Carrier' }, { id: 'alerts__alert_try', name: 'Counter' }, { id: 'last_connect', name: 'Last Connect' }, { id: 'status', name: 'Status' }, { id: null, name: 'Action' }];
export const TopDevicesTableColumns = [{ id: 'name', name: 'Name' }, { id: 'priority', name: 'Priority' }, { id: 'state', name: 'State' }, { id: 'mac', name: 'MAC' }, { id: 'asset_id', name: 'Asset ID' }, { id: 'description', name: 'Description' }, { id: 'custom2', name: 'Order' }, { id: 'group_id__group_name', name: 'Group' }, { id: 'last_connect', name: 'Last Connect' }, { id: 'status', name: 'Status' }];
export const DevicesTableColumns = [{ id: 'name', name: 'Name' }, { id: 'priority', name: 'Priority' }, { id: 'state', name: 'State' }, { id: 'mac', name: 'MAC' }, { id: 'asset_id', name: 'Asset ID' }, { id: 'description', name: 'Description' }, { id: 'custom2', name: 'Order' }, 'Group', { id: 'last_connect', name: 'Last Connect' }, { id: 'status', name: 'Status' }];
export const AlertsTableColumns = [{ id: 'name', name: 'Name' }, { id: 'priority', name: 'Priority' }, { id: 'state', name: 'State' }, { id: 'mac', name: 'MAC' }, { id: 'asset_id', name: 'Asset ID' }, { id: 'description', name: 'Description' }, { id: 'custom2', name: 'Order' }, 'Group', 'Carrier', 'Counter', { id: 'last_connect', name: 'Last Connect' }, { id: 'status', name: 'Status' }, { id: null, name: 'Action' }];
export const UsersTableColumns = [{ id: 'first_name', name: 'First Name' }, { id: 'last_name', name: 'Last Name' }, { id: 'email', name: 'Email' }, { id: 'type', name: 'Role' }, { id: 'is_active', name: 'Active' }, { id: 'is_blocked', name: 'Blocked' }, 'Action'];