import axios from "axios";

const Refresh = (token) => {
    try {
        return axios.post(`${process.env.REACT_APP_API_URL}/token/refresh/`, {
            refresh: token,
        });
    } catch (error) {
        console.error(error);
    }
}

export default Refresh;