import React, {useState} from 'react';
import { Container, CssBaseline, Typography, Button, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import InputField from '../../common/InputField';
import MainLogo from '../../assets/images/login-logo.png';
import { resetPasswordRequest } from '../../services/apis/authApi'

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [forgotErrorMsg, setForgotErrorMsg] = useState('');
  const [email, setEmail] = useState('');

  const validateForgot = event => {
    event.preventDefault()
    if (email === '') {
      setForgotErrorMsg("Please provide Email");
    } else {
      resetPasswordRequest(email).then(
        res => {
          if (res && res.status === 200 && res.data) {
            navigate("/reset-email-sent");
          } else {
            setForgotErrorMsg(res.data.details);
          }
        }
      ).catch(err => {
        console.error('ERROR =>', err.response.data);
        setForgotErrorMsg(err.response.data.details);
      })
    }
  }
  return (
    <Container component="main" maxWidth="xl" className="main-container" sx={{ display: 'flex', backgroundColor: '#007B51', minHeight: '100vh' }} >
      <CssBaseline />
      <Box className="auth">
        <img src={MainLogo} alt="logo" className='main-logo' />
        <Typography component="h1" variant="h5">Forgot Password</Typography>
        <Typography component="span" variant="h5" sx={{ width: '55%' }}>Enter your email. We’ll send you a link to reset your password.</Typography>
        <Box component="form" noValidate sx={{ mt: 3, width: '100%' }} onSubmit={validateForgot} >
          <InputField name="email" label="Email" type='email' placeholder="Email" onChange={(e)=>setEmail(e.target.value)} />
          {forgotErrorMsg && <Typography component="span" variant="h5" sx={{ color: 'red' }}>{forgotErrorMsg}</Typography>}
          <Button
            type="submit"
            variant="contained" fullWidth sx={{ p: '10px 0', m: '15px 0', fontWeight: '500' }} color='primary'>RESET PASSWORD</Button>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Remember Password? <Link to='/login' style={{ color: '#186542', textDecoration: 'none' }} >Log In</Link></Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default ForgetPassword;