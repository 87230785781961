import React, { useState, useEffect } from 'react';
import { Box, Card, Table, TableBody, TableRow, TableCell, Typography, LinearProgress, Select, MenuItem } from '@mui/material';
import SearchBar from '../../common/SearchBar';
import { useSelector } from 'react-redux';
import { getDeviceById, updateDeviceStage, createATicket } from '../../services/apis/devicesApi';
import { useParams } from 'react-router-dom';
import './style.scss';

const DeviceDetail = () => {
  const user = useSelector(state => state.auth.user);
  const access = useSelector(state => state.auth.access_token);
  const [inputState, setInputState] = useState({ cycle_start_day: '-1', cycle_start_month: '-1', data_cap: '2' });
  const { id } = useParams();
  const deviceStages = useSelector(state => state.device.devicesList);
  const [deviceDetail, setDeviceDetail] = useState(false)
  const [netDevices, setNetDevices] = useState({})

  useEffect(() => {
    if (access && access !== "") {
      getDeviceDetail()
    }
  }, [access])

  const handleChange = (event) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value })
  }

  const getDeviceDetail = () => {
    getDeviceById(id).then(result => {
      if (result && result.status === 200 && result.data) {
        setDeviceDetail(result.data);
        setNetDevices(result.data?.net_devices[0]);
      }
    })
  }

  const updateDeviceStatus = (event, deviceId) => {
    const query = { 'status': event.target.value }
    updateDeviceStage(query, deviceId).then(result => {
      if (result && result.status === 200) {
        alert("Status has been updated successfully")
      } else if (result && result.status === 400) {
        alert('ALERT: ' + result.data.details)
        event.target.value = deviceDetail.status
      }
    }).catch(err => {
      alert('ALERT: ' + err.response.data.details)
    })
  }

  const updateDevicePriority = (event, deviceId) => {
    const query = { 'priority': event.target.value }

    updateDeviceStage(query, deviceId).then(result => {
      if (result && result.status === 200) {
        alert("Priority has been updated successfully")
      } else {
        if (result)
          alert('ALERT: ' + result.details)
        else
          alert('ALERT: Something went wrong!')
      }
    })
  }

  const updateCycleDate = (deviceId) => {
    if (inputState.cycle_start_month === '-1' || inputState.data_cap === '-1' || inputState.cycle_start_day === '') {
      alert('ALERT: Please provide Data cap, Cycle start month and day to update.')
      return
    }
    updateDeviceStage(inputState, deviceId).then(result => {
      if (result && result.status === 200) {
        alert("Cycle date updated successfully!")
      } else {
        if (result && result.details)
          alert('ALERT: ' + result.details)
        else
          alert('ALERT: Something went wrong!')
      }
    })
  }

  const createTicket = (id) => {
    createATicket(id).then(
      res => {
        if (res && (res.status === 201 || res.status === 200)) {
          console.log(res, "Success");
        }
      }
    ).catch(err => {
      console.error(err);
    })
  }
  return (
    <>
      <SearchBar label="Device Detail" off={true} />
      <Box className="device-detail" >
        <Table size="medium" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{deviceDetail.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell sx={{ textTransform: 'uppercase' }}>{deviceDetail.state} <span style={{ marginLeft: 40 }}>{deviceDetail && deviceDetail.last_connect == null ? '[ Connected ]' : deviceDetail && '[' + deviceDetail.last_connect + ' ]'}</span></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>MAC</TableCell>
              <TableCell>{deviceDetail.mac}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Serial</TableCell>
              <TableCell>{deviceDetail.serial_number}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Asset ID</TableCell>
              <TableCell>{deviceDetail.asset_id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>{deviceDetail.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Order</TableCell>
              <TableCell>{deviceDetail.custom2}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>IP Address</TableCell>
              <TableCell>{deviceDetail.ipv4_address}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table size="medium" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell>Group</TableCell>
              <TableCell>{deviceDetail?.group?.group_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Carrier</TableCell>
              <TableCell>{netDevices?.carrier_id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>
                <Select
                  defaultValue={deviceDetail.status}
                  onChange={(e) => updateDeviceStatus(e, deviceDetail.id)}
                  sx={{ height: '24px', color: '#fff', fontSize: '12px', border: '1px solid #fff', mr: '15px', 'svg': { color: '#fff' } }}
                >
                  {deviceStages?.map(status => (
                    <MenuItem key={status} value={status} sx={{ fontSize: '12px' }}>{status}</MenuItem>
                  ))}
                </Select>
                <button onClick={() => createTicket(deviceDetail.id)} disabled={user.user_type === "read_only"} className="create-ticket" style={{ padding: '6px 8px' }}> Create Ticket </button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Priority</TableCell>
              <TableCell>
                <Select
                  defaultValue={deviceDetail.priority}
                  onChange={(e) => updateDevicePriority(e, deviceDetail.id)}
                  sx={{ height: '24px', color: '#fff', fontSize: '12px', border: '1px solid #fff', 'svg': { color: '#fff' } }}
                >
                  {['P1', 'P0'].map(status => (
                    <MenuItem key={status} value={status === 'P1' ? true : false} sx={{ fontSize: '12px' }}>{status}</MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data cap</TableCell>
              <TableCell>
                <Select
                  value={inputState.data_cap}
                  onChange={handleChange}
                  sx={{ height: '24px', color: '#fff', fontSize: '12px', border: '1px solid #fff', 'svg': { color: '#fff' } }}
                >
                  {[2, 5, 10, 25, 50].map(status => (
                    <MenuItem key={status} value={status} sx={{ fontSize: '12px' }}>{status} GB</MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cycle start date</TableCell>
              <TableCell>
                <Select
                  value={inputState.cycle_start_month}
                  onChange={handleChange}
                  sx={{ height: '24px', color: '#fff', fontSize: '12px', border: '1px solid #fff', 'svg': { color: '#fff' }, mr: '5px' }}
                >
                  <MenuItem value="-1" sx={{ fontSize: '12px' }}>MM</MenuItem>
                  {Array.from({length: 12}, (_, i) => i + 1).map(status => (
                    <MenuItem key={status} value={status} sx={{ fontSize: '12px' }}>{status}</MenuItem>
                  ))}
                </Select>
                <Select
                  value={inputState.cycle_start_day}
                  onChange={handleChange}
                  sx={{ height: '24px', color: '#fff', fontSize: '12px', border: '1px solid #fff', 'svg': { color: '#fff' } }}
                >
                  <MenuItem value="-1" sx={{ fontSize: '12px' }}>DD</MenuItem>
                  {Array.from({length: 30}, (_, i) => i + 1).map(status => (
                    <MenuItem key={status} value={status} sx={{ fontSize: '12px' }}>{status}</MenuItem>
                  ))}
                </Select>
                <button onClick={() => updateCycleDate(deviceDetail.id)} className="create-ticket" style={{ padding: '6px 8px' }}> Apply </button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Percentage</TableCell>
              <TableCell>{netDevices?.data_percentage && netDevices?.data_percentage != null ? netDevices?.data_percentage : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Speed Test</TableCell>
              <TableCell><button className="create-ticket" style={{ padding: '6px 8px', marginRight: '5px' }}> Upload </button><button className="create-ticket" style={{ padding: '6px 8px' }}> Download </button></TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Card sx={{background: 'transparent'}}>
          <div className='device-metrics'>
            <Typography component="h2">Baseline Metrics</Typography>
            <div>
              <Typography component="h3">Signal Strength</Typography>
              <LinearProgress variant="determinate" value={
                netDevices?.signal_strength && netDevices?.signal_strength != null ? (netDevices?.signal_strength < 0 ? netDevices?.signal_strength * (-1) : netDevices?.signal_strength) : 0
              } className='stats-progress-blue' />
              <div className='stats-num'><span>{(netDevices?.signal_strength && netDevices?.signal_strength != null ? netDevices?.signal_strength + '%' : '')}</span></div>
            </div>
            <div>
              <Typography component="h3">RSSI</Typography>
              <LinearProgress variant="determinate" value={
                netDevices?.rssi && netDevices?.rssi != null ? (netDevices?.rssi < 0 ? netDevices?.rssi * (-1) : netDevices?.rssi) : 0
              } className='stats-progress-blue' />
              <div className='stats-num'><span>{(netDevices?.rssi && netDevices?.rssi != null ? netDevices?.rssi + '%' : '')}</span></div>
            </div>
            <div>
              <Typography component="h3">SINR</Typography>
              <LinearProgress variant="determinate" value={
                netDevices?.sinr && netDevices?.sinr != null ? (netDevices?.sinr < 0 ? netDevices?.sinr * (-1) : netDevices?.sinr) : 0
              } className='stats-progress-blue' />
              <div className='stats-num'><span>{(netDevices?.sinr && netDevices?.sinr != null ? netDevices?.sinr + '%' : '')}</span></div>
            </div>
            <div>
              <Typography component="h3">RSRQ</Typography>
              <LinearProgress variant="determinate" value={
                netDevices?.rsrq && netDevices?.rsrq != null ? (netDevices?.rsrq < 0 ? netDevices?.rsrq * (-1) : netDevices?.rsrq) : 0
              } className='stats-progress-red' />
              <div className='stats-num'><span>{(netDevices?.rsrq && netDevices?.rsrq != null ? netDevices?.rsrq + '%' : '')}</span></div>
            </div>
            <div>
              <Typography component="h3">RSRP</Typography>
              <LinearProgress variant="determinate" value={
                netDevices?.rsrp && netDevices?.rsrp != null ? (netDevices?.rsrp < 0 ? netDevices?.rsrp * (-1) : netDevices?.rsrp) : 0
              } className='stats-progress-red' />
              <div className='stats-num'><span>{(netDevices?.rsrp && netDevices?.rsrp != null ? netDevices?.rsrp + '%' : '')}</span></div>
            </div>
          </div>
        </Card>
        <Card sx={{background: 'transparent'}}>
          <div className='device-metrics'>
            <Typography component="h2">Live Metrics</Typography>
            <div>
              <Typography component="h3">Signal Strength</Typography>
              <LinearProgress variant="determinate" value={
                netDevices?.current_signal_strength && netDevices?.current_signal_strength != null ? (netDevices?.current_signal_strength < 0 ? netDevices?.current_signal_strength * (-1) : netDevices?.current_signal_strength) : 0
              } className='stats-progress-blue' />
              <div className='stats-num'><span>{(netDevices?.current_signal_strength && netDevices?.current_signal_strength != null ? netDevices?.current_signal_strength + '%' : '')}</span></div>
            </div>
            <div>
              <Typography component="h3">RSSI</Typography>
              <LinearProgress variant="determinate" value={
                netDevices?.current_rssi && netDevices?.current_rssi != null ? (netDevices?.current_rssi < 0 ? netDevices?.current_rssi * (-1) : netDevices?.current_rssi) : 0
              } className='stats-progress-blue' />
              <div className='stats-num'><span>{(netDevices?.current_rssi && netDevices?.current_rssi != null ? netDevices?.current_rssi + '%' : '')}</span></div>
            </div>
            <div>
              <Typography component="h3">SINR</Typography>
              <LinearProgress variant="determinate" value={
                netDevices?.current_sinr && netDevices?.current_sinr != null ? (netDevices?.current_sinr < 0 ? netDevices?.current_sinr * (-1) : netDevices?.current_sinr) : 0
              } className='stats-progress-blue' />
              <div className='stats-num'><span>{(netDevices?.current_sinr && netDevices?.current_sinr != null ? netDevices?.current_sinr + '%' : '')}</span></div>
            </div>
            <div>
              <Typography component="h3">RSRQ</Typography>
              <LinearProgress variant="determinate" value={
                netDevices?.current_rsrp && netDevices?.current_rsrp != null ? (netDevices?.current_rsrp < 0 ? netDevices?.current_rsrp * (-1) : netDevices?.current_rsrp) : 0
              } className='stats-progress-red' />
              <div className='stats-num'><span>{(netDevices?.current_rsrp && netDevices?.current_rsrp != null ? netDevices?.current_rsrp + '%' : '')}</span></div>
            </div>
            <div>
              <Typography component="h3">RSRP</Typography>
              <LinearProgress variant="determinate" value={
                netDevices?.current_rsrq && netDevices?.current_rsrq != null ? (netDevices?.current_rsrq < 0 ? netDevices?.current_rsrq * (-1) : netDevices?.current_rsrq) : 0
              } className='stats-progress-red' />
              <div className='stats-num'><span>{(netDevices?.current_rsrq && netDevices?.current_rsrq != null ? netDevices?.current_rsrq + '%' : '')}</span></div>
            </div>
          </div>
        </Card>
      </Box>
    </>
  )
}

export default DeviceDetail;