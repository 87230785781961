import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const DenseTable = ({ children, head, getData }) => {

  return (
    <TableContainer 
      component={Paper} 
      sx={{ 
        overflowY: 'hidden', 
        display: 'grid', 
        background: 'transparent',
        boxShadow: 'none'
      }}>
      <Table sx={{ minWidth: 650, whiteSpace: 'nowrap' }} size="small" aria-label="a dense table">
        <TableHead sx={{ backgroundColor: '#00704A', 'th': {
          borderColor: '#2A2A2A'
        } }}>
          <TableRow sx={{
            'th': {
              color: '#fff',
              fontSize: '12px',
              fontWeight: '700',
            }
          }}>
            {head?.map((item) => (
              <TableCell key={item.id} onClick={() => item.id !== null && getData(item.id)} sx={{cursor: 'pointer'}}>{item.name}</TableCell>

            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{
          'td': {
            fontSize: '12px',
            color: '#fff',
            borderColor: '#2A2A2A'
          },

        }}>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DenseTable;
