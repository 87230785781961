import React, { useEffect, useState } from 'react';
import { Box, TableRow, TableCell, Grid, Avatar, Divider } from '@mui/material'
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTopAlerts, 
  getTopDevices, 
  getMerakiDeviceStatus, 
  getCradleDeviceStatus, 
  // getStatusAverageTime, 
  getDeviceByStatus, 
  createATicket 
} from '../../services/apis/devicesApi';
import * as DevicesAction from '../../services/actions/devicesAction';
import CradleStats from '../../common/CradleStats';
import MerakiStats from '../../common/MerakiStats';
import DeviceStages from '../../common/DeviceStages';
import DenseTable from '../../common/DenseTable';
import { TopAlertTableColumns, TopDevicesTableColumns } from '../../utils/Constraints';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const displayCurrentTime = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  const [currentDateAndTime, setCurrentDateAndTime] = useState(displayCurrentTime(new Date()));
  const [topAlertsData, setTopAlertsData] = useState([])
  const [topDevicesData, setTopDevicesData] = useState([])
  // const [averageTimingData, setAverageTimingData] = useState([])
  const [merakiStatsData, setMerakiStatsData] = useState([])
  const [cradleStatsData, setCradleStatsData] = useState({ online: 0, offline: 0 })
  const [devicesByStatusData, setDevicesByStatusData] = useState([])

  const [loadingMerakiStats, setLoadingMerakiStats] = useState(true)
  const [loadingCradleStats, setLoadingCradleStats] = useState(true)
  const [loadingDevicesByStatus, setLoadingDevicesByStatus] = useState(true)
  const [autoInterval, setAutoInterval] = useState()
  const access = useSelector(state => state.auth.access_token);
  const currentUser = useSelector(state => state.auth.user);
  const [topAlertSortDirection, setTopAlertSortDirection] = useState('')
  const [deviceSortDirection, setDeviceSortDirection] = useState('')


  useEffect(() => {
    if (access && access !== "" && access !== null) {
      loadDashboard()
      initTimer()
      initDashboardRefresh()
    }
    return () => {
      const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
      for (let i = 1; i < interval_id; i++) {
        window.clearInterval(i);
      }
    };
  }, [access])

  const initTimer = () => {
    let secTimer = setInterval(() => {
      setCurrentDateAndTime(displayCurrentTime(new Date()))
    }, 1000)
    return () => clearInterval(secTimer);
  }

  const initDashboardRefresh = () => {
    setAutoInterval(setInterval(() => {
      initAutoReloadDashboard()
    }, 300000))
    return () => stopAutoTimer();
  }

  const initAutoReloadDashboard = () => {
    console.error('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
    var currentdate = new Date();
    console.error("Last Sync: " + currentdate);
    loadDashboard()
    console.error('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
  }
  const stopAutoTimer = () => {
    clearInterval(autoInterval)
    console.error('>>>>>>>>>>>>> STOP  >>>>>>>>>>>>>>>>>>>>>>')
  }
  const loadDashboard = () => {
    getTopAlertsForDashboard()
    getTopDevicesForDashboard()
    getMerakiStatsData()
    getCradleStatsData()
    // getStatusAverageTimeForDashboard()
    getDevicesByStatsData()
  }
  const getTopAlertsForDashboard = (sort, direction) => {
    getTopAlerts(sort, direction).then(result => {
      if (result && result.status === 200 && result.data) {
        setTopAlertsData(result.data.results);
      }
    }).catch(err => {
      console.log(err)
    })
  }
  const getTopDevicesForDashboard = (sort, direction) => {
    getTopDevices(sort, direction).then(result => {
      if (result && result.status === 200 && result.data) {
        setTopDevicesData(result.data.results);
      }
    }).catch(err => {
      console.log(err);
    })
  }
  const getMerakiStatsData = () => {
    getMerakiDeviceStatus().then(result => {
      if (result && result.status === 200 && result.data) {
        setMerakiStatsData(result.data);
        setLoadingMerakiStats(false);
      }
    }).catch(err => {
      console.log(err);
    })
  }
  const getCradleStatsData = () => {
    getCradleDeviceStatus().then(result => {
      if (result && result.status === 200 && result.data) {
        setCradleStatsData(result.data);
        setLoadingCradleStats(false);
      }
    }).catch(err => {
      console.log(err);
    })
  }
  const getDevicesByStatsData = () => {
    getDeviceByStatus().then(result => {
      if (result && result.status === 200 && result.data) {
        setDevicesByStatusData(result.data);
        let devicesList = Object.keys(result.data)
        devicesList.pop()
        dispatch({
          type: DevicesAction.DEVICES_STATUS,
          payload: devicesList
        })
        setLoadingDevicesByStatus(false);
      }
    }).catch(err => {
      console.log(err)
    })
  }
  // const getStatusAverageTimeForDashboard = () => {
  //   getStatusAverageTime().then(result => {
  //     if (result && result.status === 200 && result.data) {
  //       result.data.baseline = result.data.average_baseline_time.split(':')[0]
  //       result.data.maintenance = result.data.average_maintenance_time.split(':')[0]
  //       setAverageTimingData(result.data);
  //     }
  //   }).catch(err => {
  //     console.log(err);
  //   })
  // }

  const createTicket = (item) => {
    createATicket(item.id).then(
      res => {
        if (res && (res.status === 201 || res.status === 200)) {
          setTopAlertsData(topAlertsData.filter(alert => alert.id !== item.id));
        } else {
          alert(res.message)
        }
      }
    )
  }

  const sortAlertColumn = (col) => {
    if (topAlertSortDirection === '') {
      setTopAlertSortDirection('asc')
      getTopAlertsForDashboard(col, 'asc')
    } else if (topAlertSortDirection === 'asc' || topAlertSortDirection === 'desc') {
      if (topAlertSortDirection === 'asc') {
        getTopAlertsForDashboard(col, 'desc')
        setTopAlertSortDirection('desc')
      } else {
        setTopAlertSortDirection('asc')
        getTopAlertsForDashboard(col, 'asc')
      }
    }
  }

  const sortDeviceColumn = (col) => {
    if(deviceSortDirection === ''){
        setDeviceSortDirection('asc')
        getTopDevicesForDashboard(col, 'asc')
    } else if(deviceSortDirection === 'asc' || deviceSortDirection === 'desc'){
        if(deviceSortDirection === 'asc'){
            getTopDevicesForDashboard(col, 'desc')
            setDeviceSortDirection('desc')
        } else {
            getTopDevicesForDashboard(col, 'asc')
            setDeviceSortDirection('asc')
        }
    }
 }
  return (
    <div className='dashboard'>
      <div className='header'>
        <h1>Dashboard</h1>
        <div className='right-section'>
          <span className='time'>{currentDateAndTime}</span>
          <span className='avatar'>
            <Avatar alt={user?.name} src='null' />
            <span>{user?.name}</span>
          </span>
        </div>
      </div>
      <Grid container pt={1.55} className='main-border' height={'100%'}>
        <Grid item md={5.9}>
          <Box className="graph-panels">
            <MerakiStats loader={loadingMerakiStats} data={merakiStatsData} />
            <CradleStats loader={loadingCradleStats} data={cradleStatsData} />
          </Box>
          <Box sx={{marginLeft: '8px'}}>
            <DeviceStages 
              loader={loadingDevicesByStatus} 
              // averageTiming={averageTimingData} 
              totaldevices={devicesByStatusData} 
              devicestages={devicesByStatusData} 
              />
          </Box>
        </Grid>
        <Divider orientation={window.outerWidth > 769 ? "vertical": "horizontal"} flexItem sx={window.outerWidth > 769 ? { mr: "5px", borderColor: '#000000', height: '98%' }: { display: 'none' }} />
        <Grid item md={6}>
          <Box className='top-alert'>
            <h3>Top Alerts</h3>
            <DenseTable head={TopAlertTableColumns} getData={sortAlertColumn}>
              {topAlertsData?.map(item => {
                let carrier_id = item?.net_devices[0]
                let alert_try = item?.alerts[0]
                return (
                  <TableRow
                    key={item.name}
                  >
                    <TableCell sx={{ 'a': { color: '#fff' } }}>{user?.user_type !== 'read_only' ? <Link to={`/device-detail/${item.id}`}>{item.name}</Link> : `${item.name}`}</TableCell>
                    <TableCell>{item.priority ? "1" : ""}</TableCell>
                    <TableCell sx={{ color: item.state === "online" ? "green !important" : 'red !important', textTransform: 'uppercase' }}>{item.state}</TableCell>
                    <TableCell>{item.mac}</TableCell>
                    <TableCell>{item.asset_id}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.custom2}</TableCell>
                    <TableCell>{item.group?.group_name}</TableCell>
                    <TableCell>{carrier_id && carrier_id?.carrier_id}</TableCell>
                    <TableCell>{alert_try.alert_try != null ? alert_try?.alert_try : ''}</TableCell>
                    <TableCell>{item.last_connect}</TableCell>
                    <TableCell sx={{textTransform: 'capitalize'}}>{item.status}</TableCell>
                    <TableCell><button onClick={() => createTicket(item)} disabled={currentUser.user_type === "read_only"} className="create-ticket"> Create Ticket </button></TableCell>
                  </TableRow>
                )
              })}
            </DenseTable>
          </Box>
          <Box className='top-devices'>
            <h3>Top Devices by Data Usage</h3>
            <DenseTable head={TopDevicesTableColumns} getData={sortDeviceColumn}>
              {topDevicesData?.map(item => (
                <TableRow
                  key={item.name}
                >
                  <TableCell sx={{ 'a': { color: '#fff' } }}>{user?.user_type !== 'read_only' ? <Link to={`/device-detail/${item.id}`}>{item.name}</Link> : `${item.name}`}</TableCell>
                  <TableCell>{item.priority ? "1" : ""}</TableCell>
                  <TableCell sx={{ color: item.state === "online" ? "green !important" : 'red !important', textTransform: 'uppercase' }}>{item.state}</TableCell>
                  <TableCell>{item.mac}</TableCell>
                  <TableCell>{item.asset_id}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.custom2}</TableCell>
                  <TableCell>{item.group?.group_name}</TableCell>
                  <TableCell>{item.last_connect === null ? <span style={{ color: 'green' }}>Connected</span> : item.last_connect}</TableCell>
                  <TableCell sx={{textTransform: 'capitalize'}}>{item.status}</TableCell>
                </TableRow>
              ))}
            </DenseTable>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default Dashboard;