import React from 'react';
import { FormControl, OutlinedInput } from '@mui/material';

const InputField = ({placeholder,name,type,onChange}) => {
    return (
        <FormControl fullWidth variant="outlined" className="input-field">
            <OutlinedInput
            placeholder={placeholder}
                onChange={onChange}
                type={type || 'text'}
                name={name}
                // error={errors?.[name]}
                required
            />
            {/* {errors?.[name] && <FormHelperText error={errors?.[name]} id={name} component="span" >{errorText}</FormHelperText>} */}
        </FormControl>
    )
}

export default InputField