import React, { useState } from 'react';
import { Container, CssBaseline, Typography, Button, Grid, Box } from '@mui/material';
import InputField from '../../common/InputField';
import MainLogo from '../../assets/images/login-logo.png';
import { setPassword } from '../../services/apis/authApi';
import { useNavigate } from 'react-router-dom';


const WriteNewPassword = () => {
  const [newPwdErrorMsg, setNewPwdErrorMsg] = useState('');
  const navigate = useNavigate();

  const validateNewPwd = event => {
    event.preventDefault()
    const elem = event.target.elements;
    const hashStr = window.location.pathname.split('write-new-password/');
    if (hashStr && hashStr[1] && hashStr[1] !== '') {
      if (elem.password.value === '' || elem.confirm_password.value === '') {
        setNewPwdErrorMsg("Please provide Password and Confirm Password.");
      } else if (elem.password.value !== elem.confirm_password.value) {
        setNewPwdErrorMsg("Password and Confirm Password doesn't match.");
      } else {
        setPassword(hashStr[1], elem.password.value, elem.confirm_password.value).then(
          res => {
            if (res && res.status === 200 && res.data) {
              navigate("/password-changed");
            } else {
              setNewPwdErrorMsg(res.detail);
            }
          }
        ).catch(err => {
          setNewPwdErrorMsg(err.response.data.detail);
          console.error(err);
        })
      }
    } else {
      setNewPwdErrorMsg('Your request is expired - Please contact Website administrator.');
    }


  }
  return (
    <Container component="main" maxWidth="xl" className="main-container" sx={{ display: 'flex', backgroundColor: '#007B51', minHeight: '100vh' }} >
      <CssBaseline />
      <Box className="auth">
        <img src={MainLogo} alt="logo" className='main-logo' />
        <Typography component="h1" variant="h5" sx={{ width: '80%' }} align='center'>Write your new password</Typography>
        <Typography component="span" variant="h5" sx={{ width: '60%' }} >Your new password must not be the same to your current password.</Typography>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={validateNewPwd}>
          <Grid container>
            <InputField name="password" label="Password" type='password' placeholder="Password" />
            <InputField name="password" label="Password" type='password' placeholder="Confirm Password" />
          </Grid>
          {newPwdErrorMsg && <Typography component="span" variant="h5" sx={{ color: 'red', width: '100%' }}>{newPwdErrorMsg}</Typography>}
          <Button
            type="submit"
            variant="contained" fullWidth sx={{ p: '10px 0', m: '15px 0' }} color='primary'>Confirm New Password</Button>
        </Box>
      </Box>
    </Container>
  )
}

export default WriteNewPassword;