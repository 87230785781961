import React from 'react'
import 'echarts-for-react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
  
function PieChart({data}) {
    const pieOptions = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: '1%',
            textStyle:{
                color:'#fff'
             },
            formatter: text => {
                const value = data.data.find(item => item.name === text)?.value
                const name = text.split(" ")[1]
                return  `${value} ${name}`;
              },
        },
        color: data.colors,
        series: [
            {
                name: '',
                type: 'pie',
                clockwise: false,
                radius: ['0%', '70%'],
                avoidLabelOverlap: true,
                itemStyle: {
                    borderRadius: 0,
                    borderColor: '#fff',
                    borderWidth: 0
                },
                label: {
                    show: false,
                    position: 'center'
                },
                labelLine: {
                    show: true
                },
                data: data.data
            }
        ]
    }

    return (
        <ReactEChartsCore
            echarts={echarts}
            option={pieOptions}
        />
    )
}
export default PieChart