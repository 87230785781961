import React, { useEffect, useState } from 'react';
import { Box, TableRow, TableCell, Select, MenuItem } from '@mui/material';
import DenseTable from '../../common/DenseTable';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getAllDevices, updateDeviceStage, searchDevicesApi } from '../../services/apis/devicesApi';
import SearchBar from '../../common/SearchBar';
import { DevicesTableColumns } from '../../utils/Constraints';
import { DevicesColors } from '../../utils/Constraints'
import Pagination from '../../common/Pagination';

const Devices = () => {
  const location = useLocation()
  const urlParams = useParams()
  const [currentSortDirection, setCurrentSortDirection] = useState('')
  const [currentSortBy, setCurrentSortBy] = useState('')
  const [allDevicesData, setAllDevicesData] = useState([])
  const [paginationIndex, setPaginationIndex] = useState(1)
  const [totalRecords, setTotalRecords] = useState()
  const [currentStatus, setCurrentStatus] = useState('')
  const [searchKeyword, setSearchKeyword] = useState("")
  const limit = 25
  const deviceStatus = useSelector(state => state.device.devicesList);
  const user = useSelector(state => state.auth.user)
  const access = useSelector(state => state.auth.access_token)
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);

  useEffect(() => {
    setCurrentStatus(urlParams && urlParams.stage && urlParams.stage !== '' ? urlParams.stage : '')
    devices(currentStatus, 1, searchKeyword, limit, currentSortBy, currentSortDirection, currentPage)
    setSearchKeyword("")
    setPaginationIndex(1)
  }, [location.pathname,access])

  const devices = (currentStatus, page, searchKeyword, limit, sortBy, sortDirection, url) => {
    let stage = ''
    if (urlParams && urlParams.stage && urlParams.stage !== '') {
      stage = urlParams.stage
      setCurrentStatus(stage)
    }
    if (searchKeyword && searchKeyword !== "") {
      searchDevicesApi(searchKeyword, sortBy, sortDirection, url, stage).then(res => {
        if (res && res.status === 200) {
          if (res.data.results.length === 0) {
            setAllDevicesData([]);
            setTotalRecords(null);
            setNextPage(null);
            setPrevPage(null);
          } else {
            setAllDevicesData(res.data.results);
            setTotalRecords(res.data.count);
            setNextPage(res.data.next);
            setPrevPage(res.data.previous);
          }
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      getAllDevices(stage, page, sortBy, sortDirection, url).then(
        (result) => {
          if (result && result.status === 200 && result.data) {
            if (result.data.results.length === 0) {
              setAllDevicesData([]);
              setTotalRecords(0);
            } else {
              setAllDevicesData(result.data.results);
              setTotalRecords(result.data.count);
              setNextPage(result.data.next);
              setPrevPage(result.data.previous);
            }
          } else {
            setAllDevicesData([]);
            setTotalRecords(0);
          }
        }
      ).catch(err => {
        console.log(err);
      })
    }
  }

  const sortDataColumn = (col) => {

    if (currentSortDirection === '') {
      if (currentSortBy !== col) {
        setCurrentSortDirection('asc')
      } else {
        setCurrentSortDirection('asc')
      }
      setCurrentSortBy(col)
      devices(currentStatus, 1, searchKeyword, limit, col, 'asc')
    } else if (currentSortDirection === 'asc' || currentSortDirection === 'desc') {
      if (currentSortDirection === 'asc') {
        if (currentSortBy !== col) {
          setCurrentSortDirection('asc')
          devices(currentStatus, 1, searchKeyword, limit, col, 'asc')
        } else {
          devices(currentStatus, 1, searchKeyword, limit, col, 'desc')
          setCurrentSortDirection('desc')
        }
        setCurrentSortBy(col)

      } else {
        devices(currentStatus, 1, searchKeyword, limit, col, 'asc')
        setCurrentSortDirection('asc')
        setCurrentSortBy(col)
      }
    }
    setPaginationIndex(1);
  }

  const handlePreviousPage = () => {
    if (paginationIndex > 1) {
      setPaginationIndex(paginationIndex - 1)
      setCurrentPage(prevPage)
      devices(currentStatus, paginationIndex - limit, searchKeyword, limit, currentSortBy, currentSortDirection, prevPage);
    }
  }

  const handleNextPage = () => {
    let totalPage = totalRecords / limit;
    if (paginationIndex < totalPage) {
      setPaginationIndex(paginationIndex + 1)
      setCurrentPage(nextPage)
      devices(currentStatus, paginationIndex + limit, searchKeyword, limit, currentSortBy, currentSortDirection, nextPage)
    }
  }

  const searchDevices = () => {
    setCurrentSortBy("");
    setCurrentSortDirection("");
    devices(currentStatus, 1, searchKeyword, limit);
    setPaginationIndex(1);
  };

  const updateDeviceStatus = (event, deviceId) => {
    const params = { 'status': event.target.value }
    updateDeviceStage(params, deviceId).then(result => {
      console.log(result);
      if (result?.status === 200) {
        const changedDevices = allDevicesData.filter(item => item.id !== deviceId)
        setAllDevicesData(changedDevices)
        setTotalRecords(totalRecords - 1);
      }
    }).catch(err => {
      console.log(err);
      event.target.value = currentStatus
    })
  }

  return (
    <>
      <SearchBar label="Devices" off="search" searchKeyword={setSearchKeyword} search={searchDevices} />
      <Box sx={{ p: '20px 10px' }} className="main-border" >
        <DenseTable head={DevicesTableColumns} getData={sortDataColumn} >
          {allDevicesData.length > 0 ? allDevicesData?.map(item => (
            <TableRow
              key={item.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 }
              }}
            >
              <TableCell sx={{ 'a': { color: '#fff' } }}>{user?.user_type !== 'read_only' ? <Link to={`/device-detail/${item.id}`}>{item.name}</Link> : `${item.name}`}</TableCell>
              <TableCell>{item.priority ? "1" : ""}</TableCell>
              <TableCell sx={{ color: item.state === "online" ? "green !important" : 'red !important', textTransform: 'uppercase' }}>{item.state}</TableCell>
              <TableCell>{item.mac}</TableCell>
              <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.asset_id}</TableCell>
              <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.description}</TableCell>
              <TableCell>{item.custom2}</TableCell>
              <TableCell>{item.group?.group_name}</TableCell>
              <TableCell sx={{ 'span': { color: 'green' } }}>{item.last_connect === null ? <span>Connected</span> : item.last_connect}</TableCell>
              <TableCell>
                <Select
                  value={item.status}
                  onChange={(e) => updateDeviceStatus(e, item.id)}
                  sx={{ width: '130px', height: '22px',color: DevicesColors[item.status], fontSize: '12px', textTransform: 'capitalize', background: '#fff' }}
                >
                  {deviceStatus.map(status => (
                    <MenuItem key={status} value={status} sx={{ color: DevicesColors[status], fontSize: '12px', textTransform: 'capitalize' }}>{status}</MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
          )) : <TableRow sx={{ 'td': { fontWeight: 600 } }}><TableCell colSpan={DevicesTableColumns.length} sx={{ textAlign: 'center' }}>No Devices</TableCell></TableRow>}
        </DenseTable>
        {totalRecords>0 && <Pagination handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} limit={limit} paginationIndex={paginationIndex} totalRecords={totalRecords} />}
      </Box>
    </>
  )
}

export default Devices