import {createStore} from 'redux';
import rootReducer from './reducers';
import { persistReducer,persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'sullymac-root',
    storage
}

const reducers = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store);

export default store;