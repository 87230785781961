import React, { useState, useEffect } from 'react';
import { Box, TableRow, TableCell, Select, MenuItem } from '@mui/material';
import { getAllAlerts, createATicket, updateDeviceStage, searchDevicesApi } from '../../services/apis/devicesApi';
import { AlertsTableColumns } from '../../utils/Constraints';
import DenseTable from '../../common/DenseTable';
import SearchBar from '../../common/SearchBar';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DevicesColors } from '../../utils/Constraints';
import Pagination from '../../common/Pagination';

const Alerts = () => {
  const [currentSortDirection, setCurrentSortDirection] = useState('')
  const [currentSortBy, setCurrentSortBy] = useState('')
  const [allAlertsData, setAllAlertsData] = useState([])
  const [paginationIndex, setPaginationIndex] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchKeyword, setSearchKeyword] = useState("")
  const [currentStatus, setCurrentStatus] = useState('staging')
  const limit = 25
  const deviceStages = useSelector(state => state.device.devicesList);
  const user = useSelector(state => state.auth.user);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const access = useSelector(state => state.auth.access_token);



  useEffect(() => {
    if (access && access !== '') {
      if (currentPage || searchKeyword || currentSortBy !== '') {
        alerts(paginationIndex, searchKeyword, limit, currentSortBy, currentSortDirection, currentPage)
      }
    }
  }, [access])

  useEffect(() => {
    if (!(currentPage || searchKeyword || currentSortBy !== '')) {
      alerts(paginationIndex, searchKeyword, limit)
      setCurrentStatus(deviceStages[0]?.title)
    }
  }, [access])

  const alerts = (page, searchKeyword, limit, sortBy, sortDirection, url) => {
    if (searchKeyword && searchKeyword !== '') {
      searchDevicesApi(searchKeyword, sortBy, sortDirection, url).then(res => {
        if (res && res.status === 200) {
          setAllAlertsData(res.data.results);
          setTotalRecords(res.data.count);
          setNextPage(res.data.next);
          setPrevPage(res.data.previous);
        }
      })
    } else {
      getAllAlerts(sortBy, sortDirection, url).then(result => {
        if (result && result.status === 200) {
          if (result.data.results.length > 0) {
            setAllAlertsData(result.data.results);
            setTotalRecords(result.data.count);
            setNextPage(result.data.next);
            setPrevPage(result.data.previous);
          } else {
            setAllAlertsData([]);
          }
        } else {
          setAllAlertsData([])
          setTotalRecords(0)
        }
      })
    }
  }

  const sortDataColumn = (col) => {
    if (currentSortDirection == '') {
      if (currentSortBy !== col) {
        setCurrentSortDirection('asc')
      } else {
        setCurrentSortDirection('asc')
      }
      setCurrentSortBy(col)
      alerts(1, searchKeyword, limit, col, 'asc')
    } else if (currentSortDirection === 'asc' || currentSortDirection === 'desc') {
      if (currentSortDirection === 'asc') {
        if (currentSortBy !== col) {
          setCurrentSortDirection('asc')
          alerts(1, searchKeyword, limit, col, 'asc')
        } else {
          alerts(1, searchKeyword, limit, col, 'desc')
          setCurrentSortDirection('desc')
        }
        setCurrentSortBy(col)

      } else {
        alerts(1, searchKeyword, limit, col, 'asc')
        setCurrentSortDirection('asc')
        setCurrentSortBy(col)
      }
    }
    setPaginationIndex(1)
  }

  const handlePreviousPage = () => {
    if (paginationIndex > 1) {
      setPaginationIndex(paginationIndex - 1)
      setCurrentPage(prevPage)
      alerts(paginationIndex - 1, searchKeyword, limit, currentSortBy, currentSortDirection, prevPage)
    }
  }

  const handleNextPage = () => {
    let totalPage = totalRecords / limit;
    if (paginationIndex < totalPage) {
      setPaginationIndex(paginationIndex + 1)
      setCurrentPage(nextPage)
      alerts(paginationIndex + 1, searchKeyword, limit, currentSortBy, currentSortDirection, nextPage)
    }
  }

  const searchAlerts = () => {
    setCurrentSortBy('')
    setCurrentSortDirection('')
    alerts(1, searchKeyword, limit)
    setPaginationIndex(1)
  }

  const createTicket = (item) => {
    createATicket(item.id).then(
      res => {
        if (res && (res.status === 201 || res.status === 200)) {
          alerts(1, searchKeyword, limit)
          setTotalRecords(totalRecords - 1);
        } else {
          alert(res.message)
        }
      }
    ).catch(err => {
      console.error('ERROR =>', err);
    })
  }

  const updateDeviceStatus = (event, deviceId) => {
    const params = { 'status': event.target.value }
    updateDeviceStage(params, deviceId).then(result => {
      if (result && result.status === 200) {
        const changedDevices = allAlertsData.filter(item => item.id !== deviceId)
        setAllAlertsData(changedDevices)
        setTotalRecords(totalRecords - 1);
      } else {
        alert('ALERT: ' + result.message)
        event.target.value = currentStatus
      }
    }).catch(err => {
      console.error('ERROR =>', err);
    })
  }

  return (
    <>
      <SearchBar label="Alerts" off="search" searchKeyword={setSearchKeyword} search={searchAlerts} />
      <Box sx={{ p: '20px 10px' }} className="main-border">
        <DenseTable head={AlertsTableColumns} getData={sortDataColumn} >
          {allAlertsData.length > 0 ? allAlertsData?.map(item => (
            <TableRow
              key={item.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 }
              }}
            >
              <TableCell sx={{ 'a': { color: '#fff' } }}>{user?.user_type !== 'read_only' ? <Link to={`/device-detail/${item.id}`}>{item.name}</Link> : `${item.name}`}</TableCell>
              <TableCell>{item.priority ? "1" : ""}</TableCell>
              <TableCell sx={{ color: item.state === "online" ? "green !important" : 'red !important', textTransform: 'uppercase' }}>{item.state}</TableCell>
              <TableCell>{item.mac}</TableCell>
              <TableCell>{item.asset_id}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>{item.custom2}</TableCell>
              <TableCell>{item.group?.group_name}</TableCell>
              <TableCell>{item.last_connect}</TableCell>
              <TableCell>{item.net_devices?.length > 0 && item.net_devices[0]?.carrier_id}</TableCell>
              <TableCell>{item?.alerts?.length > 0 && item?.alerts[0]?.alert_try != null ? item?.alerts[0]?.alert_try : ''}</TableCell>
              <TableCell>
                <Select
                  value={item.status}
                  onChange={(e) => updateDeviceStatus(e, item.id)}
                  sx={{ width: '130px', height: '22px', color: DevicesColors[item.status], fontSize: '12px', textTransform: 'capitalize', background: '#fff' }}
                >
                  {deviceStages.map(status => (
                    <MenuItem key={status} value={status} sx={{ color: DevicesColors[status], fontSize: '12px',textTransform: 'capitalize' }}>{status}</MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell><button onClick={() => createTicket(item)} disabled={user.user_type === "read_only"} className="create-ticket"> Create Ticket </button></TableCell>
            </TableRow>
          )) : <TableRow><TableCell colSpan={AlertsTableColumns.length} sx={{ textAlign: 'center' }}>No Alerts</TableCell></TableRow>}
        </DenseTable>
        {totalRecords > 0 && <Pagination totalRecords={totalRecords} limit={limit} handlePreviousPage={handlePreviousPage} paginationIndex={paginationIndex} handleNextPage={handleNextPage} />}
      </Box>
    </>
  )
}

export default Alerts