import axios from 'axios';
import store from '../services/store';

let baseURL = `${process.env.REACT_APP_API_URL}`

let headers = {
    'Content-Type': 'application/json',
}

const instance = axios.create({baseURL,headers});

instance.interceptors.request.use(config =>{
    const auth = store.getState().auth;
    const token = localStorage.getItem('access') || auth.access_token; 
    if(config.url !== 'user/login') {
        if(token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
})

export default instance;