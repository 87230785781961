import React, { useState, useEffect } from 'react';
import { Container, CssBaseline, Typography, Button, Grid, Box,FormHelperText } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import InputField from '../../common/InputField';
import MainLogo from '../../assets/images/login-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { getLogIn } from '../../services/apis/authApi';
import * as AuthActions from '../../services/actions/authAction';

const Login = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({ email: '', password: '' });
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    checkUserWasExpired()
  }, [])

  const checkUserWasExpired = () => {
    if (!user?.access || user?.access === '') {
      setError(!user?.is_blocked ? null : 'Your User is blocked.');
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
    } else if (user?.access && user?.access !== '') {
      setError('')
      navigate("/dashboard");
    }
  }

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleFormSubmission = event => {
    event.preventDefault()
    if (data.email === '' && data.password === '') {
      setError("Please provide Email and Password.");
      return;
    }
    if (data.email === '') {
      setError("Please provide Email.");
    } else {
      setError('')
    }
    if (data.password === '') {
      setError("Please provide Password.");
    } else {
      setError('')
    }

    if (data.email !== '' && data.password !== '') {
      getLogIn(data.email, data.password).then(
        res => {
          if (res.status === 200) {
            dispatch({
              type: AuthActions.USER_LOGIN,
              payload: res.data.data
            })
            localStorage.setItem('access', res.data.data.access);
            localStorage.setItem('refresh', res.data.data.refresh)
            navigate("/dashboard");
          }
        }
      ).catch(err => {
        setError(err?.response?.data?.details);
        console.log(err)
      })
    }
  }
  return (
    <Container component="main" maxWidth="xl" className="main-container" sx={{ display: 'flex', backgroundColor: '#007B51', minHeight: '100vh', minWidth: '100vw' }} >
      <CssBaseline />
      <Box className="auth">
        <img src={MainLogo} alt="logo" className='main-logo' />
        <Typography component="h1" variant="h5">SysMon</Typography>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleFormSubmission} >
          <Grid container>
            <InputField name="email" type='email' placeholder="Email" onChange={handleChange} />
            <InputField name="password" type='password' placeholder="Password" onChange={handleChange} />
          </Grid>
          {error !=="" && <FormHelperText error={error} component="span" color='red' >{error}</FormHelperText>}
          <Button
            type="submit"
            variant="contained" fullWidth sx={{ p: '10px 0', m: '15px 0' }} color='primary'>Log In</Button>
          <Typography variant="body1"><Link to='/forgot-password'>Forget Password</Link></Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default Login