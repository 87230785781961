import React, { useState } from 'react';
import { Box, TextField, Button, FormHelperText, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import SearchBar from '../../common/SearchBar';
import { saveUserApi } from '../../services/apis/authApi';
import { useNavigate } from 'react-router-dom';
import './style.scss';

const CreateUser = () => {
  const [data, setData] = useState({ firstName: '', lastName: '', email: '', user_type: '' });
  const navigate = useNavigate();
  const [formErrorMsg, setFormErrorMsg] = useState({})
  const [savingMsg, setSavingMsg] = useState('')

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const saveUser = event => {
    event.preventDefault()
    var formValidated = true;
    let errorMsg = {}
    if (data.firstName === '') {
      formValidated = false
      errorMsg = { ...errorMsg, firstName: "Please provide First Name." }
    }
    if (data.lastName === '') {
      formValidated = false
      errorMsg = { ...errorMsg, lastName: "Please provide Last Name." }
    }
    if (data.email === '') {
      formValidated = false
      errorMsg = { ...errorMsg, email: "Please provide valid Email ID." }
    }
    if (data.user_type === '') {
      formValidated = false
      errorMsg = { ...errorMsg, user_type: "Please provide User Type." }
    }


    if (formValidated === true) {
      setSavingMsg('Saving please wait ...')
      saveUserApi(data.firstName, data.lastName, data.email, data.user_type).then(
        res => {
          console.error('RESPONSE =>', res);
          if (res && res.status === 201) {
            navigate("/users");
          } else {
            console.error(res.message)
            setSavingMsg(res.message)
          }
        }
      )
    } else {
      setFormErrorMsg(errorMsg);
    }
  }

  return (
    <>
      <SearchBar label="Create User" off="none" />
      <Box className='create-user main-border'>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={saveUser} >
          <label>First Name</label>
          <TextField fullWidth name="firstName" onChange={handleChange} />
          {formErrorMsg.firstName && <FormHelperText sx={{ color: 'red', fontSize: '12px',m:0 }}>{formErrorMsg.firstName}</FormHelperText>}
          <label>Last Name</label>
          <TextField fullWidth name="lastName" onChange={handleChange} />
          {formErrorMsg.lastName && <FormHelperText sx={{ color: 'red', fontSize: '12px',m:0 }}>{formErrorMsg.lastName}</FormHelperText>}
          <label>Email Address</label>
          <TextField fullWidth name="email" onChange={handleChange} />
          {formErrorMsg.email && <FormHelperText sx={{ color: 'red', fontSize: '12px',m:0 }}>{formErrorMsg.email}</FormHelperText>}
          <RadioGroup row name="user_type" value={data.user_type} onChange={handleChange} sx={{'.MuiRadio-root':{color: '#fff'}}}>
            <FormControlLabel value="super_admin" control={<Radio />} label="Super Admin" />
            <FormControlLabel value="admin" control={<Radio />} label="Admin" />
            <FormControlLabel value="read_only" control={<Radio />} label="Read Only" />
          </RadioGroup>
          {formErrorMsg.user_type && <FormHelperText sx={{ color: 'red', fontSize: '12px',m:0 }}>{formErrorMsg.user_type}</FormHelperText>}
          {savingMsg && <FormHelperText sx={{ color: 'green', fontSize: '12px',m:0 }}>{savingMsg}</FormHelperText>}
          <Button
            type="submit"
            variant="contained" fullWidth sx={{ p: '10px 0', m: '15px 0' }}>Create User</Button>
        </Box>
      </Box>
    </>
  )
}

export default CreateUser