import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../components/Login';
import Dashboard from '../components/Dashboard';
import { Main } from './ui';
import PrivateRoute from '../utils/PrivateRoute';
import Sidebar from './Sidebar';
import Devices from '../components/Devices';
import Alerts from '../components/Alerts';
import Users from '../components/Users';
import DeviceDetail from '../components/Devices/detail';
import CreateUser from '../components/Users/create';
import ForgetPassword from '../components/ForgetPassword';
import WriteNewPassword from '../components/WriteNewPassword';
import SetPassword from '../components/SetPassword';
import ResetEmailSent from '../components/ResetEmailSent';
import PasswordChange from '../components/PasswordChanged';

const PrivateRoutes = () => {
  const [width, setWidth] = useState(240);
  const [open, setOpen] = useState(true);
  return [
    {
      path: '/dashboard',
      component: <Dashboard />,
    },
    {
      path: '/devices',
      component: <Devices />,
    },
    {
      path: '/devices/:stage',
      component: <Devices />,
    },
    {
      path: '/alerts',
      component: <Alerts />,
    },
    {
      path: '/users',
      component: <Users />,
    },
    {
      path: '/device-detail/:id',
      component: <DeviceDetail />,
    },
    {
      path: '/create-user',
      component: <CreateUser />,
    },
  ].map(({ path, component }) => {
    return (
      <Route path={path} key={path} element={
        <Sidebar width={width} open={open} setOpen={setOpen} setWidth={setWidth}>
          <Main open={open} width={width}>
            <PrivateRoute>
              {component}
            </PrivateRoute>
          </Main>
        </Sidebar>
      } />
    )
  })
}

const Navigation = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgetPassword />} />
        <Route path='/write-new-password' element={<WriteNewPassword />} />
        <Route path='/set-password' element={<SetPassword />} />
        <Route path='/reset-email-sent' element={<ResetEmailSent />} />
        <Route path='/password-changed' element={<PasswordChange />} />
        <Route path='/' element={<Navigate to="/login" replace />} />
        {PrivateRoutes()}
      </Routes>
    </React.Fragment>
  )
}

export default Navigation