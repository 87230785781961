import React, { useEffect, useState } from "react";
import PreLoader from "../PreLoader";
import { Box, Card, LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { DevicesColors } from "../../utils/Constraints";
import { useSelector } from "react-redux";
import "./style.scss";

function DeviceStages(props) {
  const [titles, setTitles] = useState([]);
  const currentUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    let devices = Object.entries(props.devicestages);
    devices.pop();
    setTitles(devices);
  }, [props.devicestages]);

  return (
    <Box className="cradle-stats-average">
      {/* <Box sx={{display: 'flex', gap: '4px'}}>
                <Card className='cradle-status'>
                    <h5>Baseline Average</h5>
                    <p>{props.averageTiming.average_baseline_time || 0}</p>
                </Card>
                <Card className='cradle-status'>
                    <h5>Maintenance Average</h5>
                    <p>{props.averageTiming.average_maintenance_time || 0}</p>
                </Card>
            </Box> */}
      <Card
        sx={{
          minWidth: 275,
          padding: "50px 0 0 10px",
          backgroundColor: "transparent",
          boxShadow: 'none'
        }}
      >
        {props.loader ? (
          <PreLoader />
        ) : (
          <div className="device-stats">
            {titles.map((stage, index) => (
              <div key={index} style={{ marginTop: 5 }}>
                <Link
                  to={
                    currentUser?.user_type === "read_only"
                      ? "/dashboard"
                      : "/devices/" + stage[0]
                  }
                >
                  {stage[0]}
                </Link>
                <LinearProgress
                  variant="determinate"
                  value={(stage[1].online / props?.devicestages?.total) * 100}
                  sx={{
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: DevicesColors[stage[0]],
                    },
                  }}
                />
                <div className="stats-num">
                  <span>{stage[1]?.online}</span> /{" "}
                  <span>{stage[1]?.offline}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
    </Box>
  );
}
export default DeviceStages;
