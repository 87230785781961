import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import PreLoader from '../PreLoader';
import PieChart from '../PieChart';
import './style.scss'

const MerakiStats = ({ data, loader }) => {
    const merakiDataForChart = {
        data: [
            { value: data.online, name: 'Meraki Online' },
            { value: data.offline, name: 'Meraki Offline' }
        ],
        colors: [
            '#00704A',
            '#D8493E'
        ]
    }
    return (
        <Box className='cradle-stats'>
            {/* <Card className='cradle-status'>
                <h5>Meraki Online</h5>
                <p>{data?.online || 0}</p>
            </Card>
            <Card className='cradle-status'>
                <h5>Meraki Offline</h5>
                <p>{data?.offline || 0}</p>
            </Card> */}
            <Card sx={{ minWidth: 275,backgroundColor: 'transparent', color: '#fff', boxShadow: 'none' }}>
                <Typography fontSize={'20px'} sx={{mb: '-15px', mt: '10px'}} textAlign={'center'}> Meraki </Typography>
                {loader ?
                    <PreLoader />
                    :
                    <PieChart data={merakiDataForChart} />
                }
            </Card>
        </Box>
    )
}

export default MerakiStats