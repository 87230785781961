import Request from '../../utils/Request';

const getLogIn = async (email, password) => {
  const queryData = { email, password }
  return await Request.post('user/login', queryData)
}

const resetPasswordRequest = async email => {
  const queryData = { email }
  return await Request.post('user/forgotpassword', queryData)
}

const resetPassword = async (hash, password, confirm_password) => {
  const queryData = {
    hash,
    password,
    confirm_password
  }
  return await Request.patch(`user/resetpassword/${hash}`, queryData)
}

const setPassword = async (hash, password, confirm_password) => {
  const queryData = {
    password,
    confirm_password
  }
  return await Request.patch(`user/setpassword/${hash}`, queryData)
}

const getAllUsers = async (page, limit) => {
  return await Request.get('user/', {params:{limit}})
}

const unBlockUser = async (id) => {
  const params = { 'id': id }
  return await Request.get('user/unblockuser', {params})
}

const deleteUser = async (id) => {
return await Request.delete(`user/${id}`)
}

const saveUserApi = async (fname, lname, email, role) => {
  const queryData = { 'first_name': fname, 'last_name': lname, 'email': email, 'user_type': role }
  return await Request.post('user/registration', queryData)
}

const refreshToken = async (refresh) => {
  return await Request.post('token/refresh/',{refresh})
}

export { getLogIn, resetPasswordRequest, resetPassword, setPassword, getAllUsers, unBlockUser, deleteUser, saveUserApi, refreshToken}