import * as DevicesAction from '../actions/devicesAction';

const initialState = {
    devicesList: [],
}

const devicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case DevicesAction.DEVICES_STATUS:
      return {
        ...state,
        devicesList: action.payload,
      };
    default:
      return state;
  }
}

export default devicesReducer;